@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");
.App {
  font-family: sans-serif;
  text-align: center;
  background: #f6f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  height: 100vh;
}

.loginButton {
  border-radius: 20px;
  border: 1px solid #ff4b2b;
  background-color: #ff4b2b;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.loginButton:active {
  transform: scale(0.95);
}

.loginButton:focus {
  outline: none;
}

.loginButton.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

.loginform {
  background-color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  text-align: center;
}

.logininput {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.containers {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: calc(100vw - 100px);
  max-width: 100%;
  min-height: calc(100vh - 100px);
  margin: 20px;
}

.form-containers {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sign-in-containers {
  background-color: #ffffff;
  height: 100%;
  left: 0;
  width: 50%;
  z-index: 2;
}

.containers.right-panel-active .sign-in-containers {
  transform: translateX(100%);
}

.sign-up-containers {
  background-color: #ffffff;
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.containers.right-panel-active .sign-up-containers {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-containers {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.containers.right-panel-active .overlay-containers {
  transform: translateX(-100%);
}

.overlay {
  /* background: #ff416c;
  background: -webkit-linear-gradient(to right, #ff4b2b, #ff416c);
  background: linear-gradient(to right, #ff4b2b, #ff416c); */
  background: #ff416c;
  background: -webkit-linear-gradient(to right, #ff4b2b, #ff416c);
  background: linear-gradient(to right, #ff4b2b, #ff416c);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.containers.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.containers.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.containers.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.signin-right,
.signup-right {
  background-color: navy;
  color: white;
  font-weight: bold;
  margin-top: 24px;
  padding: 10px 80px; /* Adjust padding as needed */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.signin-right:hover,
.signup-right:hover {
  background-color: darkblue; /* Darker color on hover */
}


.googleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 1px solid red;
  color: black;
  z-index: 3;
}


.googleButton:hover {
  background-color: #e7f1ff;
}

.googleButton svg {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 10000;
}
