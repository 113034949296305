.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #fdfdfd 0%, #d6d6d6 100%);
}


.form{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.glassmorphic-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.glassmorphic-form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
}

.glassmorphic-form button {
  padding: 10px 20px;
  border: none;
  align-self: center;
  border-radius: 5px;
  background: red;
  color: white;
  margin: 0 auto;
  cursor: pointer;
  transition: background 0.3s ease;
  max-width: 200px;
  font-size: 16px;
}

.expired {
  color: red;
}
