.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #fdfdfd 0%, #d6d6d6 100%);
}

.glassmorphic-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.glassmorphic-form h2 {
  color: red;
  margin-bottom: 0;
}

.input-container {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 8px;
  width: 100%;
}

.input-container .icon {
  margin-right: 10px;
  color: red;
  font-size: 20px;
}

.input-container input {
  flex: 1;
  border: none;
  background: none;
  outline: none;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background: red;
  color: white;
  margin: 0 auto;
  cursor: pointer;
  transition: background 0.3s ease;
  width: 100%;
  max-width: 200px;
  font-size: 16px;
}

button:disabled {
  background: #ccc;
  cursor: not-allowed;
}
.message {
  background-color: lightgreen;
  color: darkgreen;
  max-width: 240px;
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.message p {
  margin: 0;
}

.error-icon {
  color: red;
  margin-right: 5px;
}

.success-icon {
  color: green;
  margin-right: 5px;
}

Link {
  color: red;
  text-decoration: none;
  margin-top: 10px;
}
